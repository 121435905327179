<template>
  <div>
    <h1>{{ $t('locale.pageTitles.404') }}</h1>
    <p>{{ $t('locale.pages.404.pageNotFound') }}</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: '404',
}
</script>

<style lang="scss" scoped>
  // 
</style>
